import React from 'react';
import styled from "styled-components";
import Page from '../components/Page';

const PageHeader = styled.h1`
  font-family: monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 50px;
  color: #FFFFFF;
  // border: 1px solid red;
`

function Portfolio () {
    return (<Page>
      <PageHeader>Portfolio</PageHeader>
      <p>Projects (Each project will have a description, Github Link, Website (if its a visual project), Technologies used ...)</p>
    </Page>);
  }
  
  export default Portfolio;
  