import React from 'react';
import Page from '../components/Page';
import styled from "styled-components";

const PageHeader = styled.h1`
  font-family: monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 50px;
  color: #FFFFFF;
  // border: 1px solid red;
`

function About() {
    return <Page>
      <PageHeader>About</PageHeader>
      <p>What I want to do with my life</p>
      <p>Personal life</p>
      <p>What I enjoy</p>
    </Page>;
  }
  
  export default About;
  