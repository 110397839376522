import React from 'react';
import styled from "styled-components";

type ContainerProps = {
    children: React.ReactNode;
};

const Container = styled.div`
    color: #FFFFFF;
    font-family: monospace;
    font-style: normal;
    font-size: 25px;
    line-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 1%;
    // border: 1px solid red;
`


function Page (props: ContainerProps) {
    return (<Container>
        {props.children}
    </Container>);
}

export default Page;