import React from 'react'
import styled from 'styled-components'
import Page from '../components/Page'
import experience from '../assets/experience.json'

const PageHeader = styled.h1`
  font-family: monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 50px;
  color: #ffffff;
`

const ExperienceContainer = styled.div`
  margin: 100px;
  display: flex;
  // border: 1px solid red;
`

const Experience = styled.div`
  margin: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Body = styled.p<{ $job?: boolean; $dates?: boolean }>``

function getExperience(experience: any) {
  return (
    <ExperienceContainer>
      {experience.map((job: any) => {
        let newEndDate = new Date(job.end_date).toDateString().slice(4)
        let newStartDate = new Date(job.start_date).toDateString().slice(4)
        return (
          <Experience>
            <h2>{job.company_name}</h2>
            <Body $job>{job.role}</Body>
            <a href={'https://' + job.website} target="_blank">
              {job.website}
            </a>
            <Body $dates>
              {newStartDate} -{' '}
              {job.end_date == 'Present'
                ? new Date().toDateString().slice(4)
                : newEndDate}
            </Body>
          </Experience>
        )
      })}
    </ExperienceContainer>
  )
}

function Home() {
  return (
    <Page>
      <PageHeader>Home</PageHeader>
      <h1>Work History</h1>
      {getExperience(experience.work_history)}
      <h1>Volunteer History</h1>
      {getExperience(experience.volunteer_history)}
    </Page>
  )
}

export default Home
